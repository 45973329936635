<template>
<div>
    <slot v-if="widgetLoad">
        <div class="tgju-widget light post mb-3">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </div>
    </slot>
    <slot v-else>
        <div v-if="friends.items.data.length && !friends_not_found" class="w100w">
            <div v-for="friend in friends.items.data" :key="friend.id" class="follow-row" style="display: block;">
                <div class="follow-row-item">
                    <div class="follow-avatar">
                        <img :src="friend.avatar ? ($helpers.api_url() + $helpers.json_pars(friend.avatar).name) : 'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
                    </div>
                    <div class="follow-title">
                        <router-link :to="{ name: 'ProfileOthers', params: { username: friend.username }}">
                            <h2>{{ friend.firstname +' '+ friend.lastname }}</h2>
                        </router-link>
                        <div class="register-date">
                            <div style="display: inline-block;">
                                <i class="uil uil-calendar-alt"></i>
                                تاریخ عضویت: {{ toRelative(friend.created_at) }}
                            </div>
                        </div>
                    </div>
                    <div class="follow-content">
                        <div class="follow-content-items" v-tooltip="'تعداد دیدگاه‌های کاربر'">
                            <h3><i class="uil uil-comment-alt-lines"></i> {{ friend.posts }} </h3>
                        </div>
                        <div class="follow-content-items" v-tooltip="'تعداد تحلیل‌های کاربر'">
                            <h3><i class="uil uil-chart"></i> {{ friend.technicals }} </h3>
                        </div>
                        <div class="follow-content-items" v-tooltip="'تعداد لایک‌های کاربر'">
                            <h3><i class="uil uil-thumbs-up"></i> {{ friend.likes }} </h3>
                        </div>
                    </div>
                    <div class="follow-btns" v-if="$helpers.userAuth()">
                        <span v-if="friends_id.includes(friend.id)" class="tgju-btn tgju-btn-danger min-w135" @click.prevent="follow('unfollow',friend.username)">
                            <i class="uil uil-times" aria-hidden="true"></i>عدم دنبال‌کردن
                        </span>
                        <span v-else class="tgju-btn tgju-btn-primary min-w135" @click.prevent="follow('follow',friend.username)">
                            <i class="uil uil-user-plus" aria-hidden="true"></i>
                            دنبال‌کردن
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="tgju-widget light post mb-3">
            <div class="no-content d-flex justify-content-center">
                <div class="text-center p-2">
                    <div class="not-found-items">
                        <i class="uil uil-users-alt"></i>
                        <br>
                        کاربری در قسمت دنبال‌کنندگان یافت نشد.
                    </div>
                </div>
            </div>
        </div>
        <div v-if="friends.items.last_page > 1" class="p-5 d-flex justify-content-center">
            <paginate :page-count="friends.items.last_page" :click-handler="getFriends" :prev-text="'قبل'" :next-text="'بعد'" :container-class="'pagination'"></paginate>
        </div>
    </slot>
</div>
</template>

<style lang="scss" scoped>
.widget-min-box-content {
    .l-title {
        display: flex;
    }

    .edit-note {
        font-size: 14px;
        cursor: pointer;
        margin-right: -3px;
    }
}
</style>

<script>
// این کامپونتت تمام اجزای و لیست فالوئر های کاربر جاری را دارا می باشد
import moment from "moment-jalaali";
import paginate from "vuejs-paginate";

export default {
    name: 'ProfileFollowingComp',
    props: ['username'],
    components: {
        paginate
    },
    data() {
        return {
            friends_not_found: false,
            friends: {
                items: {
                    data: {
                        length: undefined,
                    },
                    current_page: undefined,
                    from: undefined,
                    last_page: undefined,
                    next_page_url: undefined,
                    per_page: undefined,
                    prev_page_url: undefined,
                    to: undefined,
                    total: undefined,
                },
                current_friends_id: undefined
            },
            friends_id: undefined,
            currentRoute: this.$router.currentRoute.name,
            widgetLoad: true,
        }
    },
    mounted() {
        // دریافت لیست دوستان کاربر
        this.getFriends();
    },
    methods: {
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد درخواست فالو یا انفالو کردن کاربر مورد نظر را هندل میکند
        follow(action, username) {
            let api_data = {
                action: action,
            };

            this.$helpers.makeRequest('GET', '/user/follow/' + username, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.getFriends();
                    if (api_data.action == 'follow') {
                        this.$root.$emit('increase-followings');
                    } else {
                        this.$root.$emit('decrease-followings');
                    }
                }

                if (api_response.status == 400) {
                    Vue.prototype.$toast.warn({
                        title: 'توجه',
                        message: 'شما پیش تر این کاربر را دنبال کرده اید'
                    });
                }
            });
        },
        // این متد درخواست دریافت لیست فالور های کاربر مورد نظر را برمیگرداند
        getFriends(page) {
            let api_data = {
                username: this.username,
                page: page ? page : 1,
            };

            this.$helpers.makeRequest('GET', '/user/friends/follower', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                this.friends_not_found = false;
                if (api_response.data.response.status === 'success') {
                    this.friends = api_response.data.response.detail;
                    this.friends_id = this.friends.friends_id;
                } else {
                    this.friends_not_found = true;
                }
                this.widgetLoad = false;
            });
        },
    },
}
</script>
